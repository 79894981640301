@tailwind base;
@tailwind components;
@tailwind utilities;

@import '~react-toastify/dist/ReactToastify.css';

:root {
  --toastify-font-family: Outfit;
  --toastify-z-index: 2147483648;
  --toastify-color-progress-dark: #97d652;
}

.Toastify__close-button {
  display: contents;
}
